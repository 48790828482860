<template>

    <el-dialog
        class="editDialog"
        :append-to-body="true"
        width="600px"
        :visible.sync="showEditDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @close="$emit('update:isShow', false)"
        :show-close="true"
    >

        <div slot="title" class="title">{{ options.id ? '编辑' : '新增' }}图层目录</div>

        <div class="content" v-loading="loading">
            <el-form label-width="6em" :model="form" :rules="rules" ref="form">

                <el-form-item label="父级目录" prop="parentId" ref="parentId">

                    <el-cascader
                        v-model="form.parentId"
                        :options="treeselectOptions"
                        :props="{ expandTrigger: 'hover',checkStrictly:true,emitPath:false,label:'catalogName',value:'id' }"
                        @change="changeParentId"></el-cascader>


                </el-form-item>

                <el-form-item label="目录名称" prop="catalogName">
                    <el-input v-model="form.catalogName" placeholder="请输入目录名称" @keyup.enter.native="submit"></el-input>
                </el-form-item>


            </el-form>
        </div>

        <div slot="footer" v-show="!loading">
            <el-button type="primary" :loading="submitLoading" @click="submit">保存</el-button>
            <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
        </div>

    </el-dialog>

</template>
<script>
import mixinsForm from '@/mixins/form'

import {initTreeData} from "@/util/common";
export default {
    components: {},
    mixins: [mixinsForm],
    props: {
        listData: {
            type: Array,
            default:function(){
                return []
            }
        },
    },
    data() {
        return {
            api:{
                save:this.$api['admin/layerCatalog'].save,
            },
            treeselectOptions:[],
            rules: {
                parentId: [
                    {required: true, message: '请选择父级目录', trigger: ['blur','change']},
                    {
                        trigger: ['change', 'blur'],
                        validator: (rule, value, callback) => {
                            if(this.form.id==value){
                                callback(new Error('父级目录不能为自身'));
                            };
                            callback();
                        }
                    }
                ],
                catalogName: [
                    {required: true, message: '请填写目录名称', trigger: ['blur','change']},
                ],
            },
        };
    },
    computed: {},
    watch: {
        listData:{
            immediate:true,//初始化立即执行
            deep: true,//对象深度监测
            handler: function (newVal){
                this.treeselectOptions = [
                    {
                        id:'0',
                        catalogName:'顶级目录',
                        children:initTreeData(newVal)
                    }
                ];
            }
        },
    },
    methods: {
        changeParentId(e){
            this.$refs.form.validateField('parentId');
        },
    },
};
</script>
